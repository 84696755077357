import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PopupModal } from '../components/Modals';
import SaveLoadGeneric from '../components/SaveLoadGeneric';
import { JsonViewer } from '../components/sfm/JsonViewer';
import { MainRuleHandler } from '../components/sfm/MainRulesHandler';
import { IMainRuleSimple, IRuleSetSimple } from '../models/sfm/IMainRuleSimple';
import { AfmContextProvider, useAfmContext } from '../services/AfmContext';
import { mapAfmSimpleRulesToNhvr } from '../services/FatigueApi';
import { getSecondsFromDuration } from '../utils/dateUtils';




export default function AfmSection(props:{onRuleSetChange:(ruleSet:IRuleSetSimple)=>void}) {
  const [rules, setRules] = useState<IMainRuleSimple[]>([]);
  const [selectedRule, setSelectedRule] = useState<IMainRuleSimple>();
  const [ruleSetName, setRuleSetName] = useState<string>('');
  const [ruleSet, setRuleSet] = useState<IRuleSetSimple>({ rules: [], rulesetName: 'no-name' });
  const [showUploadDownload, setShowUploadDownload] = useState(false);
  const [nhvrFormat, setNhvrFormat] = useState<any>();
  const {contextData, setContextData} = useAfmContext()


  useEffect(() => {
    setRuleSet({ rules, rulesetName: ruleSetName });
    props.onRuleSetChange({rules:preprocessSimpleRules(rules),rulesetName:ruleSetName})

    }, [ruleSetName, rules]);


    useEffect(() => {
      console.log('contextData uploaded from child',contextData)
  
      }, [contextData]);

  /**for remonving ui related properties and formatting to meet the backedn simpleRule format */  
  function preprocessSimpleRules(rules:IMainRuleSimple[]):any[]{
        const preprocessedRules = rules.map(mRule=>{
            const mRule1 = {...mRule}
            if (mRule1.restRules){
                mRule1.restRules = mRule1.restRules.map(restRule=>{
                    const restRule1 = {...restRule}
                    if(!restRule1.isBlockBasedRest) restRule1.blockBasedRest = undefined
                    if(!restRule1.isContinuousRest) restRule1.continuousRest = undefined
                    if(!restRule1.isNightRest) restRule1.nightRest = undefined
                    return restRule1
                })
            }
            if(!mRule1.work.hasEscalatedWork) mRule1.work.escalatedWork = undefined
            return mRule1
        })
        console.log('preprocessedRules',preprocessedRules)
        return preprocessedRules        
  }

  function validateSimpleRules() {
    mapAfmSimpleRulesToNhvr({ rules: preprocessSimpleRules(rules), rulesetName: ruleSetName || 'no-name' })
      .then((data) => {
        let failedIdxs: number[] = [];
        if (data.errors) failedIdxs = Object.keys(data.errors).map(v=>Number(v))

        if (data.nhvrFormat?.rules) {
          setNhvrFormat(data.nhvrFormat)
          console.log('---------nhvrFOrmat set ',nhvrFormat)
          const updatedRules = rules.map((rule, idx) => {
            const errors = failedIdxs.includes(idx) ? data.errors[idx] : undefined;
            console.log('errors',idx,errors)
            const nhvrFormat = errors == null ? data.nhvrFormat.rules.find((r) => r.simpleRuleTag == rule.tag) : undefined;

            return { ...rule, validationResult: { errors, nhvrFormat } };
          });
          console.log('failedIdxs ', failedIdxs);
          
          console.log('convert result ', data);
          console.log('validation updated rules ', updatedRules);
          setRules(updatedRules);
        }
      })
      .catch((error) => {
        // toast.error('Load file name List Failed');
        console.log(error);
        toast.error('mapAfmSimpleRulesToNhvr API Request Failed');
      });
  }

  function onRuleSetLoaded(ruleSet: IRuleSetSimple) {
    setRules(ruleSet.rules);
    setRuleSetName(ruleSet.rulesetName);
    setShowUploadDownload(false);
  }

  function onRulesChange(rules:IMainRuleSimple[]){
    setRules(rules)

  }

  function downloadNhVr(){
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(nhvrFormat, null, 2));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", ruleSetName+"-nhvr.json");
      document.body.appendChild(downloadAnchorNode); // required for Firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

  }



  return (
    <>
     <AfmContextProvider>
      <Container>
        <Row xs={'auto'} style={{ justifyContent: 'end', alignItems: 'center' }}>
          <Button variant="outline-info" onClick={() => validateSimpleRules()}>
            Convert
          </Button>
          <Button variant="outline-primary" onClick={() => setShowUploadDownload((val) => !val)}>
            Upload/Download
          </Button>
          <Button variant="outline-secondary" disabled={!nhvrFormat} onClick={() => downloadNhVr()}>
            download NHVR
          </Button>
        </Row>
        <Row>
          <MainRuleHandler
            mainRules={rules}
            rulesetName={ruleSetName}
            onChange={onRulesChange}
            onRuleSelect={(rule) => setSelectedRule(rule)}
            onNameChange={setRuleSetName}
          />
        </Row>
        <Row>
          <Tabs defaultActiveKey="JSON_VIEW" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="JSON_VIEW" title="Json View">
              <Row>
                <Col>{selectedRule && <JsonViewer rule={{ ...selectedRule, validationResult: undefined } as IMainRuleSimple} title='Simple Rule' />}</Col>

                <Col>
                  {selectedRule &&
                    (selectedRule.validationResult?.nhvrFormat ? (
                      <JsonViewer rule={{ ...selectedRule.validationResult.nhvrFormat }} title='NHVR Rule' />
                    ) : (
                      <JsonViewer rule={{ ...selectedRule.validationResult?.errors  }}  title='NHVR Convert Errors '/>
                    ))}
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="BREACH_VIEW" title="Breaches"></Tab>
          </Tabs>
        </Row>
      </Container>
      </AfmContextProvider>
      <PopupModal title={'upload/download'} modalProps={{ size: 'sm' }} show={showUploadDownload} onClose={() => setShowUploadDownload(false)}>
        <SaveLoadGeneric sourceJson={ruleSet} fileName={ruleSet.rulesetName} onUpload={onRuleSetLoaded} />
      </PopupModal>
    </>
  );
}
