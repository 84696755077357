import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import { IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../../models/sfm/IMainRuleSimple';
import { DurationForm } from '../DurationForm';
import { getDefaultDuration, getDefaultRestRuleData, getDefaultRuleData, MainRuleData } from '../defaultData';
import { BreachHandler } from '../breach/BreachHandler';
import { useAfmContext } from '../../../services/AfmContext';
import { ruleDurationToReadable } from '../../../utils/dateUtils';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

export const RestRuleForm = (props: { restRule: IRestRuleSimple; mainRule: IMainRuleSimple; onSubmit: (data: IRestRuleSimple) => void; onReset: () => void }) => {
  const [formData, setFormData] = useState<IRestRuleSimple>(props.restRule);
  const {contextData, setContextData} = useAfmContext()
  const [childContinuosRestRef,setChildContinuosRestRef] = useState<IRestRuleSimple|undefined>()
  useEffect(() => {
    if (props.restRule) {
      setFormData({ ...props.restRule });
    }
  }, [props.restRule]);


  
  useEffect(() => {
    console.log('contextData received:',contextData)
    if(contextData?.oneDayRule.restRules){
     const childRest =  contextData.oneDayRule.restRules.find(rest=>rest.isContinuousRest)
     setChildContinuosRestRef(childRest) 
    }
  }, [contextData]);


  useEffect(() => {
    console.log('contextData : childContinuosRestRef received:',childContinuosRestRef)
    
    
  }, [childContinuosRestRef]);


  const onDataSubmit = (data: any) => {
    console.log('got mainData', data);
    setFormData(data);
    props.onSubmit(data);
  };

  return (
    <Card>
      <Card.Body>
        <Formik enableReinitialize={true} initialValues={formData} onSubmit={onDataSubmit} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, handleSubmit }) => (
            <Form>
              {/* <Form.Group>
                <Form.Label className="col-sm-2 col-form-label">Minimum Rest</Form.Label>
                <DurationForm inputDuration={formData.minRest} onReset={() => console.log('reset')} onSubmit={(data: IRuleDuration) => setFieldValue('minRest', data)} />
              </Form.Group> */}

              {/* --------block based rest----------*/}
              {/* <Form.Group>
                <Form.Switch
                  className="mt-2 mb-2"
                  type="checkbox"
                  label="isBlockBasedRest"
                  checked={values.isBlockBasedRest}
                  onChange={(e) => setFieldValue('isBlockBasedRest', e.target.checked)}
                  onBlur={handleBlur}
                  disabled={values.isContinuousRest || values.isNightRest}
                />
              </Form.Group>
              {values.isBlockBasedRest && (
                <Card >
                  <Card.Title>Block Rest</Card.Title>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">Block size</Form.Label>
                      <DurationForm
                        inputDuration={formData.blockBasedRest?.blockSize || null}
                        onReset={() => setFieldValue('blockBasedRest.blockSize', getDefaultDuration())}
                        onSubmit={(data: IRuleDuration) => setFieldValue('blockBasedRest.blockSize', data)}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              )} */}
              {/* --------continuos rest----------*/}
              <Form.Group>
                <Form.Switch
                  className="mt-2 mb-2"
                  type="checkbox"
                  label="isContinuousRest"
                  checked={values.isContinuousRest}
                  disabled={values.isBlockBasedRest || values.isNightRest || values.isEscalatedRest}
                  onChange={(e) => setFieldValue('isContinuousRest', e.target.checked)}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {values.isContinuousRest && (
                <Card>
                  <Row xs={'auto'}>
                    <Col>
                      <Card.Title>Continuos Rest</Card.Title>
                    </Col>
                    <Col className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                      {'[7 continuous hours stationary rest time]'}
                    </Col>
                  </Row>
                  <Card.Body>

                  <Form.Group>
                    <Form.Label className="col-sm-2 col-form-label">Minimum Continuos Rest</Form.Label>
                    <DurationForm inputDuration={formData.minRest} onReset={() => console.log('reset')} onSubmit={(data: IRuleDuration) => setFieldValue('minRest', data)} />
                  </Form.Group>

                  <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">Count</Form.Label>
                      <Form.Control className="mt-2 mb-2" type="number" name="continuousRest.count" onChange={handleChange} onBlur={handleBlur} value={values.continuousRest?.count} />
                    </Form.Group>

                    <Form.Group>
                      <Row xs={'auto'}>
                        <Col>
                          <Form.Switch
                            className="mt-2 mb-2"
                            type="checkbox"
                            label="hasWorkLimit"
                            checked={values.continuousRest?.hasWorkLimit}
                            onChange={(e) => setFieldValue('continuousRest.hasWorkLimit', e.target.checked)}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[ 24h continuous rest after no more than 84h work]'}
                        </Col>
                      </Row>
                    </Form.Group>
                    {values.continuousRest?.hasWorkLimit && (
                      <Form.Group>
                        <Form.Label className="col-sm-2 col-form-label">work limit</Form.Label>
                        <DurationForm
                          inputDuration={formData.continuousRest?.maxWorkInBetweenEachRest || null}
                          onReset={() => setFieldValue('continuousRest.maxWorkInBetweenEachRest', getDefaultDuration())}
                          onSubmit={(data: IRuleDuration) => setFieldValue('continuousRest.maxWorkInBetweenEachRest', data)}
                        />
                      </Form.Group>
                    )}
                  </Card.Body>
                </Card>
              )}
              {/* --------night rest--------- */}
              <Form.Group>
                <Form.Switch
                  className="mt-2 mb-2"
                  type="checkbox"
                  label="isNightRest"
                  checked={values.isNightRest}
                  disabled={values.isBlockBasedRest || values.isContinuousRest || values.isEscalatedRest}
                  onChange={(e) => setFieldValue('isNightRest', e.target.checked)}
                  onBlur={handleBlur}
                />
              </Form.Group>
              {values.isNightRest && (
                <Card>
                  <Card.Title>
                    <Row xs={'auto'}>
                      <Col>Night Rest</Col>
                      <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                        {'[ 10 PM - 6 AM]'}
                      </Form.Label>
                    </Row>
                  </Card.Title>
                  <Card.Body>
                    <Form.Group>
                      <Row xs={'auto'}>
                        <Form.Label className="col-sm-2 col-form-label">Duration</Form.Label>
                        <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[allowed max duration is 8 hrs]'}
                        </Form.Label>
                      </Row>
                      <DurationForm
                        inputDuration={formData.nightRest?.duration || null}
                        onReset={() => setFieldValue('nightRest.duration', getDefaultDuration())}
                        onSubmit={(data: IRuleDuration) => setFieldValue('nightRest.duration', data)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">Count</Form.Label>
                      <Form.Control className="mt-2 mb-2" type="number" name="nightRest.count" onChange={handleChange} onBlur={handleBlur} value={values.nightRest?.count} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Switch
                        className="mt-2 mb-2"
                        type="checkbox"
                        label="isConsecutive"
                        checked={values.nightRest?.isConsecutive}
                        onChange={(e) => setFieldValue('nightRest.isConsecutive', e.target.checked)}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              )}
              {/* --------escalated continuous rest--------- */}
              
              <Form.Group>
              <Row xs={'auto'}>
                <Form.Switch
                  className="mt-2 mb-2"
                  type="checkbox"
                  label="isEscalatedRest"
                  checked={values.isEscalatedRest}
                  disabled={values.isBlockBasedRest || values.isNightRest || values.isContinuousRest || !childContinuosRestRef}
                  onChange={(e) => {
                              setFieldValue('isEscalatedRest', e.target.checked); 
                              if(e.target.checked)setFieldValue('escalatedRest.childRuleTag',childContinuosRestRef?.tag) 
                              else setFieldValue('escalatedRest.childRuleTag',null)
                            }
                          }
                  
                              onBlur={handleBlur}
                />
                <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          { !childContinuosRestRef ? '[create a 24h rule continuos rest to enable]' : '[uses 24h rule continuos rest as the child rule]'}
                        </Form.Label>
                </Row>

              </Form.Group>
              {values.isEscalatedRest && (
                <Card>
                  <Card.Title>
                    <Row xs={'auto'}>
                      <Col>Escalated rest</Col>
                      <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                        {'[Stationary rest breaks of less than 7 hours continuous hours can be used no more than 3 times in any 7 days]'}
                      </Form.Label>
                    </Row>
                  </Card.Title>
                  <Card.Body>
                    {/* <Form.Group>
                      <Row xs={'auto'}>
                        <Form.Label className="col-form-label">min Considerable Rest from 1D rule</Form.Label>
                        <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[ron sinnamore ,5hr rest from 24hr rule]'}
                        </Form.Label>
                      </Row>
                      <Form.Label  style={{ fontWeight: '600' }} >{ruleDurationToReadable(childContinuosRestRef?.minRest)}</Form.Label>


                    </Form.Group> */}

                    <Form.Group>
                      <Row xs={'auto'}>
                        <Form.Label className="col-sm-2 col-form-label">min required rest</Form.Label>
                        <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[ron sinnamore : 7h rest from 7d escalated rule ]'}
                        </Form.Label>
                      </Row>
                      <DurationForm
                        inputDuration={formData.escalatedRest?.minRequiredRestDuration || null}
                        onReset={() => setFieldValue('escalatedRest.minRequiredRestDuration', getDefaultDuration())}
                        onSubmit={(data: IRuleDuration) => setFieldValue('escalatedRest.minRequiredRestDuration', data)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">Max allowed min rest Count</Form.Label>
                      <Form.Control
                        className="mt-2 mb-2"
                        type="number"
                        name="escalatedRest.maxCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.escalatedRest?.maxCount}
                      />
                    </Form.Group>
                    <Form.Group>
                    <Row xs={'auto'}>
                      <Form.Switch
                        className="mt-2 mb-2"
                        type="checkbox"
                        label="isNonConsecutive"
                        checked={values.  escalatedRest?.isNonConsecutive}
                        onChange={(e) => setFieldValue('escalatedRest.isNonConsecutive', e.target.checked)}
                        onBlur={handleBlur}
                      />
                      <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[false = irrespective of consecutive or non consecutive]'}
                        </Form.Label>
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              )}

              {/* <Form.Group>
                <Form.Switch
                  className="mt-2 mb-2"
                  type="checkbox"
                  label="isStationary"
                  checked={values.isStationary}
                  onChange={(e) => setFieldValue('isStationary', e.target.checked)}
                  onBlur={handleBlur}
                />
              </Form.Group> */}

              <BreachHandler
                mainRule={props.mainRule}
                breaches={props.restRule?.breachList || []}
                isWorkBreach={false}
                onChange={(data) => {
                  setFieldValue('breachList', data);
                  console.log('onchange breachHandler');
                }}
              />

              <Form.Group className="mt-3">
                <Button
                  size="lg"
                  variant="success"
                  className="btn btn-primary me-2"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button variant="warning" onClick={() => props.onReset()}>
                  RESET
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};
