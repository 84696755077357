/**represents a period of time  */
export interface IPeriod {
  from: number;
  to: number;
}

export class Period implements IPeriod {
  from: number;
  to: number;

  constructor(from: number, to: number) {
    this.from = from;
    this.to = to;
  }

  includesPeriod(from: number, to: number) {
    return from >= this.from && to <= this.to;
  }

  get duration() {
    return this.to - this.from;
  }
}
