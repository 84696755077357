export enum ActivityType {
  rest = 'rest',
  work = 'work',
}

export enum RuleCategory {
  BfmSolo = 'BFM_SOLO',
  SfmSolo = 'SFM_SOLO',
  BfmTwoUp = 'BFM_TWOUP',
  SfmTowUp = 'SFM_TWOUP',
  Afm = 'AFM'
}