import { IBreachSimple, IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../models/sfm/IMainRuleSimple';
import { v4 as uuidv4 } from 'uuid';
import { Severity } from '../../models/RuleBreachCounter';

export interface MainRuleData extends IMainRuleSimple {}

export const getDefaultDuration = (): IRuleDuration => ({ days: 0, hours: 0, minutes: 0, inclusive: false });

export const getDefaultBreach = (): IBreachSimple => ({ period: { from: getDefaultDuration(), to: getDefaultDuration() }, severity: Severity.zero, tag: uuidv4() });

export const getDefaultRuleData = (): MainRuleData => ({
  tag: uuidv4(),
  shiftRestBreak: getDefaultDuration(),
  period: getDefaultDuration(),
  majorRestBreak: getDefaultDuration(),
  work: {
    breachList: [],
    isLongOrNightWork: false,
    maxWork: getDefaultDuration(),
    hasEscalatedWork: false,
    escalatedWork:{
      acceptedWorkPerDay: getDefaultDuration(),
      childRuleTag:null
    }
  },
  restRules: [],
});

export const getDefaultRestRuleData = (): IRestRuleSimple => ({
  tag: uuidv4(),
  isStationary: false,
  minRest: getDefaultDuration(),
  isBlockBasedRest: false,
  blockBasedRest: {
    blockSize: getDefaultDuration(),
  },
  isContinuousRest: false,
  continuousRest: {
    hasWorkLimit: false,
    maxWorkInBetweenEachRest: getDefaultDuration(),
    count:0
  },
  isNightRest: false,
  nightRest: {
    count: 0,
    isConsecutive: false,
    duration: getDefaultDuration(),
  },
  isEscalatedRest: false,
    escalatedRest: {
    isNonConsecutive: false,
    maxCount: 0,
    minRequiredRestDuration: getDefaultDuration(),
    childRuleTag:null
  },
});
