import { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { IMainRuleSimple } from "../../models/sfm/IMainRuleSimple";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export const JsonViewer = ({rule,title}:{rule:IMainRuleSimple,title?:string})=>{
    const [simpleJson,setSimpleJson] = useState(rule)
    const [expand,setExpand] = useState(false)

    useEffect(()=>{
        console.log('simpleJson',simpleJson)
    },[simpleJson])

    
    useEffect(()=>{
        setSimpleJson(rule)
    },[rule])

    return(<Container>
        {  title && <Row>{title}</Row>}
          <Row>
          <Form.Switch
                        className="mt-2 mb-2"
                        type="checkbox"
                        label="Expand nodes"
                        checked={expand}
                        onChange={(e) => setExpand(val=>!val)}
                      />
          </Row>
          <JsonView data={simpleJson} shouldExpandNode={(level: number, value: any, field?: string)=>expand} style={defaultStyles} />
    </Container>)

} 