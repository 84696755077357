import { Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useRef, useState } from 'react';
import { IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../../models/sfm/IMainRuleSimple';
import { DurationForm } from '../DurationForm';
import { getDefaultDuration, getDefaultRestRuleData, getDefaultRuleData, MainRuleData } from '../defaultData';
import { PopupModal } from '../../Modals';
import { RestRuleForm } from './RestRuleForm';
import { v4 as uuidv4 } from 'uuid';
import { FaPen,FaTrash } from 'react-icons/fa6';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

interface SelectedRuleData {
  rule: IRestRuleSimple | null;
  type: 'add' | 'edit' | 'delete' | 'select';
  idx?: number;
}

export const RestRulesHandler = (props: { restRules: IRestRuleSimple[]; mainRule: IMainRuleSimple; onChange: (data: IRestRuleSimple[]) => void }) => {
  const [rules, setRules] = useState<IRestRuleSimple[]>([]);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedRuleData, setSelectedRuleData] = useState<SelectedRuleData>();
  const isParentUpdate = useRef(false);

  useEffect(() => {
    if (props.restRules) {
      isParentUpdate.current = true;
      setRules(props.restRules);
    }
  }, [props.restRules]);

  useEffect(() => {
    if (!isParentUpdate.current) props.onChange(rules);
    else isParentUpdate.current = false;
  }, [rules]);

  useEffect(() => {
    console.log('processing selectedRuleData ',selectedRuleData)
    if (selectedRuleData?.type == 'add' || selectedRuleData?.type == 'edit') {
      setShowAddEdit(true);
    }
    else if (selectedRuleData?.idx != undefined && selectedRuleData?.type == 'delete'){
            rules.splice(selectedRuleData.idx)
            setRules([...rules])
    }
  }, [selectedRuleData]);

  const onAddEditRuleComplete = (data: IRestRuleSimple) => {
    console.log('onAddEditRule', data);
    if (selectedRuleData?.type == 'add') {
      console.log('onAddEditRule --add')
      setRules([...rules, { ...data, tag: uuidv4() }]);
    }
    else if(selectedRuleData?.idx!=null &&  selectedRuleData?.type == 'edit'){
      console.log('onAddEditRule --edit')
        rules[selectedRuleData.idx] = { ...data }
        setRules([...rules]);

    }
    setShowAddEdit(false);
    setSelectedRuleData(undefined)
  };

  return (
    <>
      <Card>
        <Card.Title>Rest Rules</Card.Title>
        <Card.Body>
          <Button
            size="sm"
            variant="dark"
            className="btn btn-primary me-2"
            onClick={() => {
              setSelectedRuleData({ rule: null, type: 'add' });
            }}
          >
            Add Rest Rule
          </Button>
          <ListGroup>
            {rules?.map((rule,idx) => (
              <ListGroup.Item key={rule.tag}>
                <Row xs={2} md={4} lg={6}>
                  <Col>
                    <Row xs="auto">
                      {rule.isBlockBasedRest && <Col style={{ backgroundColor: 'antiquewhite' }}>block rest</Col>}
                      {rule.isContinuousRest && <Col style={{ backgroundColor: 'aquamarine' }}>continuos rest</Col>}
                      {rule.isNightRest && <Col style={{ backgroundColor: 'burlywood' }}>night rest</Col>}
                      {rule.isEscalatedRest && <Col style={{ backgroundColor: 'bisque' }}>escalated rest</Col>}

                    </Row>
                  </Col>
                  <Col>
                    <Button variant="light" onClick={()=>setSelectedRuleData({rule:rule,type:'edit',idx:idx})}>
                      <FaPen />
                    </Button>
                    <Button variant="light" onClick={()=>setSelectedRuleData({rule:rule,type:'delete',idx:idx})}>
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
      <PopupModal title={selectedRuleData?.type == 'add' ? 'Add Rule' : 'Edit Rule'} modalProps={{ size: 'xl' }} show={showAddEdit} onClose={() => setShowAddEdit(false)}>
        <RestRuleForm mainRule={props.mainRule} restRule={selectedRuleData?.rule || getDefaultRestRuleData()} onReset={() => console.log('restRuleResetted')} onSubmit={onAddEditRuleComplete} />
      </PopupModal>
    </>
  );
};
