import { RuleCategory } from '../common/Types';
import { ActivitiesSaveFormat } from '../components/SaveLoad';
import { Activity, IActivity, IBaseActivity } from '../models/Activity';
import { IExpectedBreachesOutput } from '../models/BreachMapper';
import { IMainRuleSimple, IRuleSetNhvrResponse, IRuleSetSimple } from '../models/sfm/IMainRuleSimple';
import { ISummary } from '../models/Summary';

const BASE_URL = process.env.REACT_APP_FATIGUE_API_ENDPOINT;
const BASE_URL_AFM = process.env.REACT_APP_FATIGUE_AFM_API_ENDPOINT;

const GET_BREACHES_API_OLD = BASE_URL + 'get-breaches-old';
const GET_BREACHES_API_NEW = BASE_URL + 'get-breaches-new';
const GET_BREACHES_API_AFM = BASE_URL_AFM + 'get-breaches-afm';
const ACTIVITY_FILE_NAMES_API = BASE_URL + 'get-activity-file-names';
const SAVE_ACTIVITY_API = BASE_URL + 'save-activity';
const GET_ACTIVITY_LIST_API = BASE_URL + 'get-activity-list';
const GET_ACTIVITY_LIST_META_DATA_API = BASE_URL + 'get-meta-data-by-file-name';
const GET_FATIGUE_SUMMARY_API = BASE_URL + 'get-summary';
const GET_FATIGUE_SUMMARY_AFM_API = BASE_URL_AFM + 'get-summary';

 const MAP_SIMPLE_RULES = BASE_URL_AFM + 'sfm/simple/convert'
/**extrac work causes breaches */
export type Breach = {
  name: string;
  severity: number;
  key: string;
  from: number;
  to: number;
  valid: boolean;
};

export type Breached = {
  key: string;
  name: string;
  severity: number;
  from: number;
  to: number;
  readable: string;
  type: string;
  ruleset: string;
  activityTime: number;
  /**breach start to end in iso string */
  breachedDuration: string;
  startPoint: number;
  endPoint: number;
  periodIndex: number;
  /**14Day ,7Day.... */
  period: string;
  ruleIndex: number;
  description: string;
  ruleId: string;
};

export interface IBreachedSaveFormat extends Breached {
  /**used to identify the position of the item in the breached list ,this is not a reliable way to do this */
  ruleCounterIdx: number;
}

export type BreakRule = {};

export type StartingPoint = {
  endTime: number;
  breaches: Breach[];
  breaks: Break[];
};

/**lesser rests make breaks*/
export type Break = {
  endTime: number;
  breaches: Breach[];
  breakRule: any;
  restType: string;
};

export type WorkBreach = {
  /**rule counter start epoch */
  startPoint: number;
  endTime: number;
  breaches: Breach[];
};

export type RestBreach = {
  /**rule counter start epoch */
  startPoint: number;
  endTime: number;
  breaches: Breach[];
  breaks: Break[];
  startingPoints: StartingPoint[];
  startingPoint: number;
};

type Duration = {
  minutes: number;
  humanized: string;
  iso: string;
  maximumWork: number;
  requiredRest: number;
};

export type RuleBreach = {
  id: number;
  endTime: number;
  /**all breaches for the rule */
  breaches: Breach[];
  /**only the work breaches for the rule */
  workRemaining: WorkBreach;
  /**only the rest breaches for the rule*/
  restToBeTaken: RestBreach;
  duration: Duration;
};

export type BreachResult = {
  breached: Breached[];
  breaches: IExpectedBreachesOutput[];
  endTime: number;
};

/**Load all filenames of the saved activityLists */
export async function getActivityFileNames(): Promise<string[]> {
  return Promise.resolve([]);
  const response = await fetch(ACTIVITY_FILE_NAMES_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data.fileNames);
  } else {
    throw Error('Fatigue API getActivityFileNames Request failed');
  }
}
/**saves activities as json file with the given fileName */
export async function saveActivitiesList(activities: ActivitiesSaveFormat, fileName: string) {
  const response = await fetch(SAVE_ACTIVITY_API, {
    method: 'POST',
    body: JSON.stringify({ data: activities, fileName }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(true);
  } else {
    throw Error('Fatigue API saveActivitiesList Request failed');
  }
}

/**Load activity list with the given fileName */
export async function getActivityList(fileName: string): Promise<IBaseActivity[]> {
  const response = await fetch(GET_ACTIVITY_LIST_API + '?fileName=' + fileName, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data.activities);
  } else {
    throw Error('Fatigue API getActivityList Request failed');
  }
}

export async function getActivityListMetaData(fileName: string): Promise<{ description?: string; subBreachName?: string }> {
  // return Promise.resolve({});
  const response = await fetch(GET_ACTIVITY_LIST_META_DATA_API + '?fileName=' + fileName, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data);
  } else {
    throw Error('Fatigue API getActivityListMetaData Request failed');
  }
}
/**@param customCurrentTime optional custom current time property for backend to use (epcoch time) */
export async function getApiBreaches(ruleType:RuleCategory,activities: IActivity[], useNewLogicApi = true, customCurrentTime?: number,ruleSet?:IRuleSetSimple): Promise<BreachResult> {

  let url = !useNewLogicApi ? GET_BREACHES_API_OLD : GET_BREACHES_API_NEW

  let body:any = {rule:ruleType, activities: activities.map((act): IBaseActivity => ({ startTime: act.startTime, type: act.type })), customCurrentTime: customCurrentTime  }

  if(ruleType == RuleCategory.Afm){
     url = GET_BREACHES_API_AFM
     body={...body,simpleRuleSet:ruleSet}
   }

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data as BreachResult);
  } else {
    throw Error('Fatigue API getBFMBreaches Request failed');
  }
}

export async function getFatigueSummary(ruleType:'AFM_SOLO' | 'BFM_SOLO' | 'SFM_SOLO' ,activities: IActivity[], customCurrentTime?: number,ruleSet?:any): Promise<ISummary> {
  if(ruleType == 'SFM_SOLO' ) throw Error('SFM not supported for summary')

   console.log('getFatigueSummary body',JSON.stringify({rule:ruleType, activities: activities.map((act): IBaseActivity => ({ startTime: act.startTime, type: act.type })), customCurrentTime: customCurrentTime ,simpleRuleSet: ruleType == 'AFM_SOLO' ? ruleSet : null}),) 

  const response = await fetch( ruleType == 'BFM_SOLO' ? GET_FATIGUE_SUMMARY_API : GET_FATIGUE_SUMMARY_AFM_API, {
    method: 'POST',
    body: JSON.stringify({rule:ruleType, activities: activities.map((act): IBaseActivity => ({ startTime: act.startTime, type: act.type })), customCurrentTime: customCurrentTime ,simpleRuleSet: ruleType == 'AFM_SOLO' ? ruleSet : null}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data as ISummary);
  } else {
    throw Error('Fatigue API getBFMBreaches Request failed');
  }
}



export async function mapAfmSimpleRulesToNhvr(simpleRuleSet:IRuleSetSimple): Promise<IRuleSetNhvrResponse> {
  const response = await fetch(MAP_SIMPLE_RULES, {
    method: 'POST',
    body: JSON.stringify(simpleRuleSet),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (response.ok) {
    return Promise.resolve(data);
  } else {
    throw Error('Fatigue API mapAfmSimpleRulesToNhvr Request failed');
  }
}
