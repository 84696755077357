/**
 * Renders selected breached elemnents indexes
 *
 */

import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import { RuleBreachCounter } from '../models/RuleBreachCounter';

export const SelectedBreached = (props: {
  selectedBreached: RuleBreachCounter[];
  selectedCounter: RuleBreachCounter | undefined;
  onSelectedUpdate: (items: RuleBreachCounter[]) => void;
}) => {
  const onAdd = () => {
    if (
      props.selectedCounter &&
      !props.selectedBreached.find(
        (item) => item.ruleId == props.selectedCounter?.ruleId && item.startTimeS === props.selectedCounter.startTimeS && item.endTimeS === props.selectedCounter.endTimeS,
      )
    ) {
      props.selectedBreached.push(props.selectedCounter);
      props.onSelectedUpdate(props.selectedBreached);
      toast.success('added breached id ' + props.selectedCounter.id);
    }
  };
  const onRemove = () => {
    if (props.selectedBreached.length === 0) return;
    const deleted = props.selectedBreached.splice(-1);
    props.onSelectedUpdate(props.selectedBreached);
    toast.success('removed breached id ' + deleted[0].id);
  };

  return (
    <div className="row w-100">
      <Button className="col" variant="success" onClick={onAdd}>
        +
      </Button>
      <Button className="col" variant="danger" onClick={onRemove}>
        -
      </Button>
      <Dropdown className="col">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          QA Selected
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.selectedBreached.map((item, index) => (
            <Dropdown.Item key={index} href="#">
              {item.id + ' > ' + item.ruleId}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
