import { Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import { IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../models/sfm/IMainRuleSimple';
import { DurationForm } from './DurationForm';
import { getDefaultDuration, getDefaultRuleData, MainRuleData } from './defaultData';
import { RestRuleForm } from './restRules/RestRuleForm';
import { RestRulesHandler } from './restRules/RestRulesHandler';
import { BreachHandler } from './breach/BreachHandler';
import { useAfmContext } from '../../services/AfmContext';
import { getSecondsFromDuration, ruleDurationToReadable } from '../../utils/dateUtils';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

export const MainRuleForm = (props: { mainRule: MainRuleData; onSubmit: (data: MainRuleData) => void; onReset: () => void }) => {
  const [formData, setFormData] = useState<MainRuleData>(props.mainRule);
  const {contextData, setContextData} = useAfmContext()

  useEffect(() => {
    if (props.mainRule) {
      console.log('MainRuleForm props.mainRule changed ', props.mainRule);
      setFormData({ ...props.mainRule });
    } else {
      setFormData(getDefaultRuleData());
    }
  }, [props.mainRule]);

  const onDataSubmit = (data: any) => {
    console.log('got mainData', data);
    props.onSubmit(data);
  };

  return (
    <>
      <Card>
        <Card.Title>Main Rule </Card.Title>
        <Card.Body>
          <Formik enableReinitialize={true} initialValues={formData} onSubmit={onDataSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, handleSubmit }) => (
              <Form>
                <Form.Group>
                  <Form.Label className="col-sm-2 col-form-label">Timeline Period</Form.Label>
                  <DurationForm inputDuration={formData.period} onReset={() => console.log('reset')} onSubmit={(data: IRuleDuration) => {
                    setFieldValue('period', data)
                    if(getSecondsFromDuration(data) < getSecondsFromDuration({days : 1 ,hours:0,minutes:0 })){
                      setFieldValue('work.hasEscalatedWork', false)
                    }
                    }} />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="col-sm-2 col-form-label">Major Rest break</Form.Label>
                  <DurationForm
                    inputDuration={formData.majorRestBreak}
                    onReset={() => console.log('reset')}
                    onSubmit={(data: IRuleDuration) => {
                      setFieldValue('majorRestBreak', data)
                    }}
                  />
                </Form.Group>
                <Form.Group>
                <Form.Label className="col-sm-2 col-form-label">Shift Rest break</Form.Label>
                <DurationForm inputDuration={formData.shiftRestBreak} onReset={() => console.log('reset')} onSubmit={(data: IRuleDuration) => {
                    setFieldValue('shiftRestBreak', data)
                    }} />
                </Form.Group>

                <Card>
                  <Card.Title>Work Rule</Card.Title>
                  <Card.Body>
                    <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">Max Work</Form.Label>
                      <DurationForm
                        inputDuration={formData.work.maxWork}
                        onReset={() => setFieldValue('work.maxWork', getDefaultDuration())}
                        onSubmit={(data: IRuleDuration) => setFieldValue('work.maxWork', data)}
                        
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Switch
                        className="mt-2 mb-2"
                        type="checkbox"
                        label="IsLongOrNightWork"
                        checked={values.work?.isLongOrNightWork}
                        onChange={(e) => setFieldValue('work.isLongOrNightWork', e.target.checked)}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Row xs={'auto'}>
                        <Col>
                        <Form.Switch
                        className="mt-2 mb-2"
                        type="checkbox"
                        label="hasEscalatedWorkLimit"
                        checked={values.work.hasEscalatedWork}
                        disabled={!contextData?.oneDayRule ||  getSecondsFromDuration(values.period) < getSecondsFromDuration({days : 1 ,hours:0,minutes:0 }) }
                        onChange={(e) =>{
                          setFieldValue('work.hasEscalatedWork', e.target.checked)
                          if(e.target.checked)setFieldValue('work.escalatedWork.childRuleTag',contextData?.oneDayRule.tag) 
                          else setFieldValue('escalatedRest.childRuleTag',null)
                        }}
                        onBlur={handleBlur}
                      /> 
                        </Col>
                         {!contextData?.oneDayRule && <Col className='d-flex align-items-center' style={{fontSize:'medium',fontWeight:'300' ,fontStyle:'italic'}}>{'[ create a 24h work rule to enable]'}</Col>}

                        <Col className='d-flex align-items-center' style={{fontSize:'medium',fontWeight:'300' ,fontStyle:'italic'}}>{'[ Exceed 14 hr work time no more than 4.5 hours in any  7 days ]'}</Col>
               
                      </Row>
                    
                    </Form.Group>
                    {values.work.hasEscalatedWork && 
                    <div>
                    {/* <Form.Group>
                      <Row xs={'auto'}>
                        <Form.Label className="col-form-label">max Considerable work per day </Form.Label>
                        <Form.Label className="d-flex align-items-center" style={{ fontSize: 'medium', fontWeight: '300', fontStyle: 'italic' }}>
                          {'[ron sinnamore ,15hr 30m work from 24hr rule]'}
                        </Form.Label>
                      </Row>
                      <Form.Label  style={{ fontWeight: '600' }} >{ruleDurationToReadable(contextData?.oneDayRule?.work.maxWork)}</Form.Label>
                    </Form.Group> */}

                    <Form.Group>
                      <Form.Label className="col-sm-2 col-form-label">accepted work per day</Form.Label>
                      <DurationForm
                        inputDuration={formData.work.escalatedWork?.acceptedWorkPerDay|| null}
                        onReset={() => setFieldValue('work.escalatedWork.acceptedWorkPerDay', getDefaultDuration())}
                        onSubmit={(data: IRuleDuration) => setFieldValue('work.escalatedWork.acceptedWorkPerDay', data)}
                      />
                    </Form.Group>

                    </div>
}

                    <BreachHandler mainRule={values} breaches={formData.work.breachList} isWorkBreach={true} onChange={(data) => setFieldValue('work.breachList', data)} />
                  </Card.Body>
                </Card>
                {/* ---------add edit rest rule */}
                <RestRulesHandler mainRule={values} restRules={formData.restRules} onChange={(data) => setFieldValue('restRules', data)} />

                <Form.Group className="mt-3">
                  <Button
                    size="lg"
                    variant="success"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button variant="warning" onClick={() => props.onReset()}>
                    RESET
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
