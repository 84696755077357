import { IMainBreach } from '../models/BreachMapper';
import { RuleType } from '../models/RuleBreachCounter';

type TBreachMappings = {
  mainBreaches: IMainBreach[];
};

/**Contains breach mappings from the NHVR Documentation in json doc format*/
export const breachMappings: TBreachMappings = {
  mainBreaches: [
    {
      name: '14 Day',
      description: '14 day rule',
      type: RuleType.Day14,
      subBreaches: [
        {
          name: '144H Max Work',
          description: 'Maximum work time of 144 hours',
          documentReference: {
            name: '144 hours work time NHVR Rule',
            description: '144 hours work time NHVR Rule',
          },
          severity: 0,
        },
        {
          name: '84 Hour Work',
          description: '24 continuous hours stationary rest time taken after no more than 84 hours work time',
          documentReference: {
            name: '14 Day 84H Work Breach NHVR Rule (a)',
            description: '14 Day NHVR Rule 1 desciption',
          },
          severity: 0,
        },
        {
          name: '24H Continuos Rest Break',
          description: '24 continuous hours stationary rest time (b)',
          documentReference: {
            name: '24H Continuos rest',
            description: '24 continuous hours stationary rest time',
          },
          severity: 0,
        },
        {
          name: '2x Night rest breaks',
          description: '2x Night rest breaks within 14 days',
          documentReference: {
            name: '2x Night rest breaks within 14 days (c)',
            description: '2x Night rest breaks within 14 days',
          },
          severity: 0,
        },
        {
          name: 'Consecutive 2x Night rest breaks',
          description: '2 x night rest breaks taken on consecutive days',
          documentReference: {
            name: 'Consecutive 2x Night rest breaks (d)',
            description: '2 x night rest breaks taken on consecutive days',
          },
          severity: 0,
        },
      ],
    },
  ],
};
