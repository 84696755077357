import { Button, Container, Form } from "react-bootstrap";
import { FaDownload} from 'react-icons/fa6';

export interface SaveLoadViewProps { 
    sourceJson:any
    fileName:string
    onUpload:(obj:any)=>void
}



const SaveLoadGeneric  = (props:SaveLoadViewProps) => {
    // const [jsonObject, setJsonObject] = useState<any>(null);

    const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = (e.target?.result as string);
                // setJsonObject();
                props.onUpload(JSON.parse(text))
            };
            reader.readAsText(file);
        }

    };



    const downloadJson = () => {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.sourceJson, null, 2));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", props.fileName+".json");
        document.body.appendChild(downloadAnchorNode); // required for Firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    return (
        <Container>
                <Form.Group className="mb-3" >
                  <Form.Label >days</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="fileName"
                      onChange={handleFileRead}
                      accept='.json'
                    />
                </Form.Group>
                <Form.Group >
                <Button
                    variant="info"
                    className="btn btn-primary me-2"
                    onClick={() => {
                        downloadJson();
                    }}
                  >
                    <FaDownload/> 
                  </Button>
                </Form.Group>

            {/* <input type='file' accept='.json' onChange={handleFileRead} /> */}
            {/* <button onClick={downloadJson} disabled={!props.sourceJson}>Download JSON</button> */}
        </Container>
    );
};



export default SaveLoadGeneric;
