import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import { IRuleDuration } from '../../models/sfm/IMainRuleSimple';

export interface DurationData {
  days: number;
  hours: number;
  minutes: number;
}

const defaultDurationData: DurationData = {
  days: 0,
  hours: 0,
  minutes: 0,
};

const validationSchema = Yup.object().shape({
  days: Yup.number().min(0),
  hours: Yup.number().min(0),
  minutes: Yup.number().min(0),
});

export const DurationForm = (props: { inputDuration: DurationData | null; onSubmit: (data: IRuleDuration) => void; onReset: () => void ,nonEditable?:boolean}) => {
  const [formData, setFormData] = useState<DurationData>({ days: 0, hours: 0, minutes: 0 });
  useEffect(() => {
    if (props.inputDuration) {
      setFormData({ ...props.inputDuration });
    } else {
      setFormData({ ...defaultDurationData });
    }
  }, [props.inputDuration]);

  const onDataSubmit = (data: any) => {
    console.log('got data', data);
    setFormData(data);
    props.onSubmit(data);
  };

  return (
    <Card>
      <Card.Body>
        <Formik enableReinitialize={true} initialValues={formData} onSubmit={onDataSubmit} validationSchema={validationSchema}  >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, handleSubmit ,dirty ,resetForm }) => (
            <Form>
              <Row>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label sm={2}>days</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="days"
                      type="number"
                      className={touched.days && errors.days ? ' error' : ''}
                      placeholder="Days"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.days}
                      contentEditable={!props.nonEditable}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label sm={2}>Hours</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="hours"
                      type="number"
                      className={touched.hours && errors.hours ? ' error' : ''}
                      placeholder="Hours"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hours}
                      contentEditable={!props.nonEditable}

                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label sm={2}>Minutes</Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="minutes"
                      type="number"
                      className={touched.minutes && errors.minutes ? ' error' : ''}
                      placeholder="Minutes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.minutes}
                      contentEditable={!props.nonEditable}

                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Col} sm={2}  className="mb-3 col-2">
                  <Row>
                    
                  {dirty &&<Button
                    variant="info"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    SET
                  </Button>}
                  </Row>
                  <Row>

                  {dirty &&<Button variant="secondary" onClick={() => resetForm()}>
                    RESET
                  </Button>}
                  </Row>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};
