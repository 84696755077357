import { Button, Card, Col, ListGroup, Row, Table } from 'react-bootstrap';
import { FaPen, FaTrash, FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { IBreachSimple, IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../models/sfm/IMainRuleSimple';
import { BreachView } from './breach/BreachHandler';

export const MainRuleView = ({
  mainRule,
  onEdit,
  onDelete,
  onClick,
  selected,
}: {
  mainRule: IMainRuleSimple;
  selected?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onClick?: () => void;
}) => {
  function getDurationText(period: IRuleDuration) {
    let str = '';
    if (period.days) str += period.days + 'D ';
    if (period.hours) str += period.hours + 'H ';
    if (period.minutes) str += period.minutes + 'M ';
    return str;
  }

  function getDurationView(period: IRuleDuration): React.ReactNode | null {
    let str = [];
    if (period.days)
      str.push(
        <Col key={1} style={{ backgroundColor: 'aliceblue' }}>
          {period.days + 'D'}
        </Col>,
      );
    if (period.hours)
      str.push(
        <Col key={2} style={{ backgroundColor: 'antiquewhite' }}>
          {period.hours + 'H'}
        </Col>,
      );
    if (period.minutes)
      str.push(
        <Col key={3} style={{ backgroundColor: 'blanchedalmond' }}>
          {period.minutes + 'M'}
        </Col>,
      );

    return str.length > 0 ? <Row>{str.map((c) => c)}</Row> : null;
  }

  function getCheckedView(isChecked: boolean) {
    if (isChecked) return <FaCircleCheck />;
    else return <FaCircleXmark />;
  }

  function getDurationItem(period: IRuleDuration|undefined, title: string) {
    if(!period)  return <div/>
    const durationView = getDurationView(period);
    if (durationView != null)
      return (
        <ListGroup.Item>
          <Row>
            <Col> {title}</Col>
            <Col>{durationView}</Col>
          </Row>
        </ListGroup.Item>
      );
    else return <div />;
  }

  function getCheckedItem(isChecked: boolean|undefined, title: string) {
    if (isChecked)
      return (
        <ListGroup.Item>
          <Row>
            <Col>{title}</Col>
            <Col>{getCheckedView(mainRule.work.isLongOrNightWork)}</Col>
          </Row>
        </ListGroup.Item>
      );
    else return <div />;
  }

  function getValueItem(value:any|undefined,title:string){
    if (value)
    return (
      <ListGroup.Item>
        <Row>
          <Col>{title}</Col>
          <Col>{value}</Col>
        </Row>
      </ListGroup.Item>
    );
  else return <div />;
  }

  
  const cardStyle = selected ? { borderColor: 'blue', cursor: 'pointer' } : { cursor: 'pointer' };

  const RestRuleView=(props:{rule:IRestRuleSimple})=>{

    return(
    <ListGroup.Item>
      <ListGroup>
      {getDurationItem(props.rule.minRest, 'min Rest')}

      <ListGroup.Item>
      {getCheckedItem(props.rule.isBlockBasedRest,'block Based Rest')}
      {getCheckedItem(props.rule.isContinuousRest,'continuous Rest')}
      {getCheckedItem(props.rule.isNightRest,'night Rest')}
      {getCheckedItem(props.rule.isEscalatedRest,'escalated rest')}

      </ListGroup.Item>
      <ListGroup.Item>
      {getCheckedItem(props.rule.continuousRest?.hasWorkLimit,'has Work Limit')}
      {getDurationItem(props.rule.continuousRest?.maxWorkInBetweenEachRest, 'work limit')}
      {getValueItem(props.rule.continuousRest?.count,'count')}

      </ListGroup.Item>
      <ListGroup.Item>
      {getCheckedItem(props.rule.nightRest?.isConsecutive,'is Consecutive')}
      {getValueItem(props.rule.nightRest?.count,'night rest count')}
      </ListGroup.Item>
      <ListGroup.Item>
      {getDurationItem(props.rule.  escalatedRest?.minRequiredRestDuration, 'minimum considered rest')}
      {getCheckedItem(props.rule.  escalatedRest?.isNonConsecutive,'is non Consecutive')}
      {getValueItem(props.rule.  escalatedRest?.maxCount,'max rest count')}
      </ListGroup.Item>
      <ListGroup.Item>
        {(props.rule.breachList && props.rule.breachList.length > 0) ? <BreachView breaches={props.rule.breachList || []} /> : <div></div>}
      </ListGroup.Item>
      </ListGroup>
    </ListGroup.Item>)
  }

  return (
    <Card style={cardStyle}>
      <Card.Title>
        <Row xs={'auto'} style={{ justifyContent: 'space-between', alignItems: 'center', backgroundColor: selected ? 'blanchedalmond' : undefined }}>
          <Col>{getDurationText(mainRule.period)} Rule</Col>
          <Col>
            <Button variant="light" onClick={() => onEdit()}>
              <FaPen />
            </Button>
            <Button variant="light" onClick={() => onDelete()}>
              <FaTrash />
            </Button>
          </Col>
        </Row>
        <Row>
        <Col style={{fontSize:'smaller',fontWeight:400,backgroundColor:'aliceblue'}}>{mainRule.majorRestBreak ?getDurationText(mainRule.majorRestBreak):'Default'} Major Rest</Col>
        <Col  hidden={mainRule.shiftRestBreak.hours == 0} style={{fontSize:'smaller',fontWeight:400,backgroundColor:'aliceblue'}}>{mainRule.shiftRestBreak ?getDurationText(mainRule.shiftRestBreak):'Default'} Shift rest</Col>
        <Col></Col>
        </Row>
      </Card.Title>
      <Card.Body onClick={(e: any) => onClick && onClick()}>
        <Row>
          <Card>
            <Card.Title>Work</Card.Title>
            <Card.Body>
              <ListGroup>
                {getDurationItem(mainRule.work.maxWork, 'maxWork')}
                {getDurationItem(mainRule.work.escalatedWork?.acceptedWorkPerDay, 'workLimitPerDay')}
                {getCheckedItem(mainRule.work.isLongOrNightWork, 'Long/Night Work')}
                <ListGroup.Item>
                     <BreachView breaches={mainRule.work.breachList} />   
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card>
            <Card.Title>Rest</Card.Title>
            <Card.Body>
              <ListGroup horizontal={'sm'}>
                {mainRule.restRules?.map((restRule,idx)=><RestRuleView key={idx} rule={restRule}/>)}
              </ListGroup>
            </Card.Body>
          </Card>
        </Row>
      </Card.Body>
    </Card>
  );
};
