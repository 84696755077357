import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { Duration } from '../models/Activity';
import { IRuleDuration } from '../models/sfm/IMainRuleSimple';
const timeZone = 'Australia/Melbourne'; //GMT+11
const timeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
const timeFormatSimple = 'DD/MM HH:mm';
const timeFormatShort = 'HH:mm';

//const timeZone = 'Asia/Colombo'
dayjs.extend(timezone);
// dayjs.tz.setDefault(timeZone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
export function nowTime() {
  return dayjs();
}

export function toDateString(time: Dayjs) {
  return time.format(timeFormat);
}

export function toSimpleDateString(time: string) {
  return dayjs(time).format(timeFormatSimple);
}

export function unixToSimpleDateString(epochTime: number) {
  return dayjs.unix(epochTime).format(timeFormatSimple);
}

export function toSimpleTimeString(time: string) {
  return dayjs(time).format(timeFormatShort);
}

export function addDurationToDate(date: string, duration: Duration): Dayjs {
  return dayjs(date).add(duration.days, 'day').add(duration.hours, 'hour').add(duration.minutes, 'minute');
}

/**add if seconds >  0 ,subtract if seconds < 0 */
export function addSecondsToDate(date: string, seconds: number): Dayjs {
  return seconds > 0 ? dayjs(date).add(seconds, 'second') : dayjs(date).subtract(Math.abs(seconds), 'second');
}

export function subtractSecondsFromDate(date: string, seconds: number): Dayjs {
  console.log('subtracting', date, seconds / 60 / 60);
  return dayjs(date).subtract(seconds, 'second');
}

/**@returns time difference in milliseconds */
export function timeDiff(startTime: string, endTime: string): number {
  return dayjs(endTime).diff(startTime, 'second');
}

export function secondsToReadable(durationSeconds: number) {
  return dayjs.duration(durationSeconds, 'second').humanize();
}

export function secondsToHoursAndMins(durationSeconds: number) {
  const { days, hours, minutes } = secondsToDuration(durationSeconds);
  console.log(days, hours, minutes);
  const totH = days * 24 + hours;
  return (totH ? totH + 'H' : '') + (minutes ? minutes + 'M' : '');
}

/** @returns 1D2H3M,2D3H,.. with up to a minute resolution */
export function secondsToISO(durationSeconds: number) {
  return dayjs.duration(Math.floor(durationSeconds / 60), 'minute').toISOString();
}

export function secondsToDuration(durationSeconds: number) {
  const dur = dayjs.duration(durationSeconds, 'second');
  return { days: dur.get('day'), hours: dur.get('hour'), minutes: dur.get('minute') };
}
export function dateToEpoch(date: string) {
  return dayjs(date).unix();
}

export function epochToDateStr(epoch: number) {
  return toDateString(dayjs.unix(epoch));
}

export function epochToSimpleDateStr(epoch: number) {
  return toSimpleDateString(toDateString(dayjs.unix(epoch)));
}

export function epochToDateObj(epoch: number) {
  return dayjs.unix(epoch).toDate();
}

export function nowTimeInEpoch() {
  return dayjs().unix();
}

export function dateToLocalDate(date: string) {
  return dayjs(date).toDate();
}

export function localDateToString(date: Date) {
  return dayjs(date).format(timeFormat);
}

export function getSecondsFromDuration(duration: Duration|IRuleDuration): number {
  return dayjs.duration(duration.minutes, 'minute').add(duration.days, 'day').add(duration.hours, 'hour').asSeconds();
}

export function getDurationFromSeconds(durationSec: number): Duration {
  const days = dayjs.duration(durationSec, 'second').asDays();
  const hours = dayjs.duration(days - Math.floor(days), 'day').asHours();
  const minutes = dayjs.duration(hours - Math.floor(hours), 'hour').asMinutes();
  return {
    days: Math.floor(days), //Math.floor(durationSec / (60 * 60 * 24)),
    hours: Math.floor(hours), // Math.floor(durationSec / (60 * 60)),
    minutes: Math.floor(minutes), //Math.floor(durationSec / 60),
  };
}

export function ruleDurationToReadable(duration?: IRuleDuration){
      return duration ?`${duration.days}D ${duration.hours}H ${duration.minutes}M` : 'Not defined'
}

/**subtract a given duration from a time in 24 hour period ,if subtractValue is negative it will be added
 *
 * ex : subtract 4H from 13:00 >> 11:00
 *
 * ex : subtract -4H from 13:00 >> 17:00
 *
 * @param subtractValue amount to subtract  (seconds)
 * @param sourceRelativeTime source to subtract from (seconds)
 *
 * @returns final relative time in seconds
 */
export function subtractRelativeTime(subtractValue: number, sourceRelativeTime: number) {
  const finalDate = dayjs().startOf('day').add(sourceRelativeTime, 'second').subtract(subtractValue, 'second');

  return finalDate.hour() * 60 * 60 + finalDate.minute() * 60 + finalDate.second();
}

/**
 *
 * @param startTime unix time
 * @param endTime  unix time
 * @returns time difference in seconds
 */

export function timeDiffFromEpochs(startTime: number, endTime: number): number {
  return dayjs.unix(endTime).diff(dayjs.unix(startTime), 'second');
}

/**returns seconds from 00:00:00 from the unix time
 *
 * if date is passed only the time part is considered
 *
 * ex : 2022-12-01T01:03:45 -> 01:03:45
 */
export function getSecondsFromStartOfDay(unixTime: number) {
  const end = dayjs.unix(unixTime);
  const dateStart = dayjs.unix(unixTime).startOf('day');
  return end.diff(dateStart, 'second');
}

/**returns the utcOffset from date string in seconds */
export function utcOffset(date: string) {
  return dayjs(date).utcOffset() * 60;
}
