import { Button, Card, Col,ListGroup, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useRef, useState } from 'react';
import { IBreachSimple, IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../../models/sfm/IMainRuleSimple';
import { PopupModal } from '../../Modals';
import { BreachForm } from './BreachForm';
import { v4 as uuidv4 } from 'uuid';
import { FaPen,FaTrash,FaPlus } from 'react-icons/fa6';
import { Severity } from '../../../models/RuleBreachCounter';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

interface SelectedBreachData {
  rule: IBreachSimple | null;
  type: 'add' | 'edit' | 'delete' | 'select';
  idx?: number;
}


const getColorBySeverity = (severity:Severity):React.CSSProperties=>{
  let color : React.CSSProperties 
  switch( severity){
    case Severity.zero:
      color = {backgroundColor:'lightcyan'}
      break;       
    case Severity.minor:
      color = {backgroundColor:'greenyellow'} 
      break;    
    case Severity.severe:
      color = {backgroundColor:'yellow'} 
      break;    
    case Severity.substantial:
      color =  {backgroundColor:'orange'} 
      break;    
    case Severity.critical:
      color =  {backgroundColor:'red'} 
      break;   
    default:
      color={} 
  }
  return color
} 

export const BreachHandler = (props: { breaches: IBreachSimple[]; mainRule: IMainRuleSimple; isWorkBreach:boolean, onChange: (data: IBreachSimple[]) => void }) => {
  const [breaches, setBreaches] = useState<IBreachSimple[]>(props.breaches?.map(breach=>({...breach})));
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedBreachData, setSelectedBreachData] = useState<SelectedBreachData>();
  const isInit = useState(false)
  useEffect(() => {
    // if (breaches.length == 0 && props.breaches) {
    //   setBreaches(props.breaches);
    // }
    // else{
      props.onChange(breaches)
    // }
  }, [breaches]);

  // useEffect(() => {
    
  // }, [breaches]);

  useEffect(() => {
    console.log('processing selecteDBreachData ',selectedBreachData)
    if (selectedBreachData?.type == 'add' || selectedBreachData?.type == 'edit') {
      setShowAddEdit(true);
    }
    else if (selectedBreachData?.idx != undefined && selectedBreachData?.type == 'delete'){
            breaches.splice(selectedBreachData.idx,1)
            console.log('breach deleted new list',breaches)
            setBreaches([...breaches])
    }
  }, [selectedBreachData]);

  const onAddEditBreachComplete = (data: IBreachSimple) => {
    console.log('onAddEditBreachComplete', data);
    if (selectedBreachData?.type == 'add') {
      console.log('onAddEditBreachComplete --add')
      setBreaches([...breaches, { ...data, tag: uuidv4() }]);
    }
    else if(selectedBreachData?.idx !=null &&  selectedBreachData?.type == 'edit'){
      console.log('onAddEditBreachComplete --edit')
        breaches[selectedBreachData.idx] = { ...data}
        setBreaches([...breaches]);

    }
    setShowAddEdit(false);
    setSelectedBreachData(undefined)
  };




  return (
    <>
      <Card>
        <Card.Title>{props.isWorkBreach?'Work ':'Rest '} Breaches</Card.Title>
        <Card.Body>
          <Button
            size="sm"
            variant="secondary"
            className="btn btn-primary me-2"
            onClick={() => {
              setSelectedBreachData({ rule: null, type: 'add' });
            }}
          >
            Add {props.isWorkBreach?' Work ':' Rest '} Breach
          </Button>
          <ListGroup>
            {breaches?.map((breach,idx) => (
              <ListGroup.Item key={breach.tag}>
                <Row xs={2} md={4} lg={6}>
                  <Col>
                    <Row xs="auto">
                      <Col style={{ ...getColorBySeverity(breach.severity) }}>{Severity[breach.severity]}</Col>
                    </Row>
                  </Col>
                  <Col>
                    <Button variant="light" onClick={()=>setSelectedBreachData({rule:breach,type:'edit',idx:idx})}>
                      <FaPen />
                    </Button>
                    <Button variant="light" onClick={()=>setSelectedBreachData({rule:breach,type:'delete',idx:idx})}>
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
      <PopupModal title={selectedBreachData?.type == 'add' ? 'Add Breach' : 'Edit Breach'} modalProps={{ size: 'lg' }} show={showAddEdit} onClose={() => setShowAddEdit(false)}>
        <BreachForm mainRule={props.mainRule} breach={selectedBreachData?.rule || null} isWorkBreach={props.isWorkBreach}  onReset={() => console.log('restRuleResetted')} onSubmit={onAddEditBreachComplete} />
      </PopupModal>
    </>
  );
};

export const BreachView= (props:{breaches:IBreachSimple[]})=>{
  return(
    <Table striped bordered hover>
    <thead>
    <tr>
      <th>#</th>
      <th>from</th>
      <th>to</th>
    </tr>
  </thead>
  <tbody>
    {props.breaches?.sort((a,b)=> a.severity - b.severity).map(breach=>
              <tr key={breach.tag}>
              <td>
              <Row xs="auto">
                  <Col style={{ ...getColorBySeverity(breach.severity) }}>{Severity[breach.severity]}</Col>
                </Row>
              </td>
              <td >{breach.period.from.days}:{breach.period.from.hours}:{breach.period.from.minutes}</td>
              <td>{breach.period.to.days}:{breach.period.to.hours}:{breach.period.to.minutes}</td>
            </tr>
            )}
  </tbody>
  </Table>)
}