/*
 *For processing all types of sub durations (Long/night work , nightBreaks ...)  within a  given Activity
 */

import { Activity } from '../models/Activity';
import * as du from '../utils/dateUtils';
import { IPeriod, Period } from '../models/Period';
import { ActivityType } from './Types';

/**Represents a time range and conditions for a work/rest type that can happen in a 24 hour period */
export type BreakDuration = {
  /** time in 24hr with hh:mm:ss format */
  readonly startTime: number;

  /** time in 24hr with hh:mm :ss format */
  readonly endTime: number;

  /**minimum duration in hours that is needed to satisfy by the activity
   * within the bounds of startTime and endTime */
  readonly minDuration?: number;

  /**if true ,the minDuration need to be exceeded by the activity to satify the break */
  readonly minDurationExclusive?: boolean;

  /**if the duration exists and needs to be contineous*/
  readonly contineous?: boolean;
  /**to which activity type the duration belongs */
  readonly type: ActivityType;
};

/**represents a single work or rest duration in an activity
 *
 * ex , if there is a requirement of minCountinuous period of 2h between 6:00 and 12:00 and if actual the time period is between 8:00 to 10:00
 * the item will contain 4H as the total and will contribute to 2 continuosDurations in DurationTypeResult
 *
 */
export type DurationTypesResultItem = {
  /** time in string*/
  startTime: string;
  /** time in string*/
  endTime: string;
  /** time in epoch*/
  startTimeS: number;
  /** time in epoch*/
  endTimeS: number;
  /**time duration in seconds */
  duration: number;
  /**time duration in ISO string */
  durationIso: string;
};

export type DurationTypeResult = {
  type: DurationType;
  /**durations in detail for the condition
   * ex , if there is a requirement of minCountinuous period of 2h between 6:00 and 12:00 and if actual the time period is between 8:00 to 10:00
   * an item will contain 4H as the total and will contribute to 2 continuosDurations in DurationTypeResult
   */
  items: DurationTypesResultItem[];
  /**total duration in seconds*/
  totalDuration: number;
  /**total duration in ISO string*/
  totalDurationStr: string;

  /**no of completed contineous durations -> totalDuration /single duration  */
  contineousCompletions: number;
};

export enum DurationType {
  nightWork = 'nightWork',
  longWork = 'longWork',
  nightRest = 'nightRest',
  hour24ContineousRest = 'hour24ContineousRest',
  hour7ContineousRest = 'hour7ContineousRest',
}

/**
 * Includes followings
 *
 * - **Long/night work time  -> longWork || nightWork
 * - #Night rest breaks  -> nightRest || hour24
 * - 24 continuous hours rest -> hour24ContineousRest
 *
 */
const breakDurations: { [key in DurationType]: BreakDuration } = {
  nightWork: { startTime: 24, endTime: 6, type: ActivityType.work, minDurationExclusive: true },
  longWork: {
    startTime: 6,
    endTime: 24,
    minDuration: 12,
    minDurationExclusive: true,
    type: ActivityType.work,
  },
  nightRest: {
    startTime: 22,
    endTime: 8,
    minDuration: 7,
    contineous: true,
    type: ActivityType.rest,
  },
  hour24ContineousRest: {
    startTime: 0,
    endTime: 24,
    minDuration: 24,
    contineous: true,
    type: ActivityType.rest,
  },
  hour7ContineousRest: {
    startTime: 0,
    endTime: 24,
    minDuration: 7,
    contineous: true,
    type: ActivityType.rest,
  },
};

const hourToSeconds = 60 * 60;
/** 24:00 Hrs */
export const hour24RelativeMaxTime = 60 * 60 * 24;
export const hour7RelativeMaxTime = 60 * 60 * 7;

//includes times converted to seconds
const breakDurationsMapped: { [key: string]: BreakDuration } = {};

Object.entries(breakDurations).forEach(([key, val]) => {
  breakDurationsMapped[key as DurationType] = { ...val, startTime: val.startTime * hourToSeconds, endTime: val.endTime * hourToSeconds };
});

/**helper function for validating a time is in between a duration type
 * all params are relative times in seconds from 00:00:00
 */
export function isTimeWithinBounds(relativeTime: number, start: number, end: number) {
  if (end - start < 0) {
    //crosses two days
    return (relativeTime >= start && relativeTime <= hour24RelativeMaxTime) || (relativeTime <= end && relativeTime >= 0);
  } else return relativeTime >= start && relativeTime <= end;
}

/**break long durations into equal parts of given size , only to be used with duration types that doesn't have limits
 * ex : 24h ,7h continuos rests
 * non applicable : night rests ,night work
 */
function breakContinuosDurations(durationType: DurationType, start: number, end: number) {
  const breakDuration = durationType === DurationType.hour24ContineousRest ? hour24RelativeMaxTime : hour7RelativeMaxTime;
  const durations: DurationTypesResultItem[] = [];
  let startTime = start;
  let endTime = startTime + breakDuration;
  if (endTime >= end) {
    //duartion is under or equal 24hrs
    durations.push({
      duration: end - start,
      durationIso: du.secondsToISO(end - start),
      startTime: du.epochToDateStr(start),
      startTimeS: start,
      endTime: du.epochToDateStr(end),
      endTimeS: end,
    });
  } else {
    //duration is greater than 24hrs
    while (endTime <= end) {
      //pushing consecutive durations seperately
      durations.push({
        duration: endTime - startTime,
        durationIso: du.secondsToISO(endTime - startTime),
        startTime: du.epochToDateStr(startTime),
        startTimeS: startTime,
        endTime: du.epochToDateStr(endTime),
        endTimeS: endTime,
      });
      startTime += breakDuration;
      endTime += breakDuration;
    }
    //pushing the remaining duration
    if (endTime > end) {
      durations.push({
        duration: end - startTime,
        durationIso: du.secondsToISO(end - startTime),
        startTime: du.epochToDateStr(startTime),
        startTimeS: startTime,
        endTime: du.epochToDateStr(endTime),
        endTimeS: end,
      });
    }
  }
  return {
    items: durations,
    totalDuration: end - start,
    totalDurationStr: du.secondsToISO(end - start),
    type: durationType,
    contineousCompletions: Math.floor((end - start) / hour24RelativeMaxTime),
  };
}

/**
 *
 * @param start in unix time
 * @param end in unix time
 * @param durationType DurationType
 * @param utcOffset custom utc offest value to move the range to match with the source time zone ,default is 0
 * @description returns the DurationTypes that are consisted within startTime and endTime
 *
 * Note that for 24hour periods since the start and endtimes doesnt has a gap.it is seperately handled within the logic
 *
 */
export function getBreakDurations(start: number, end: number, durationType: DurationType, utcOffset = 0): DurationTypeResult {
  let conditions = breakDurationsMapped[durationType];
  if (!conditions) return { contineousCompletions: 0, items: [], totalDuration: 0, totalDurationStr: '', type: DurationType.hour7ContineousRest };
  if (utcOffset !== 0 && (durationType === DurationType.longWork || durationType === DurationType.nightWork || durationType === DurationType.nightRest)) {
    const offsetStart = du.subtractRelativeTime(utcOffset, conditions.startTime);
    const offsetEnd = du.subtractRelativeTime(utcOffset, conditions.endTime);
    conditions = { ...conditions, startTime: offsetStart, endTime: offsetEnd };
  }
  //total duration in seconds
  const totalDuration = du.timeDiffFromEpochs(start, end);
  /**sum of seconds from start of day (00:00:00) */
  const relativeStartTime = du.getSecondsFromStartOfDay(start);

  /**hour which a duration count starts */
  let periodStartEpoch = 0;

  let periodCountingStarted = false;
  let periodDuration = 0;
  const durations: DurationTypesResultItem[] = [];
  let totalDurations = 0;
  let currentRelativeTime = 0;
  let timeCounter = 0;

  //------handling 24h,7h continuosBreaks seperately since it doesn't have bounds within 24hrs-----------------
  if (durationType === DurationType.hour24ContineousRest || durationType === DurationType.hour7ContineousRest) {
    return breakContinuosDurations(durationType, start, end);
  }

  for (timeCounter = relativeStartTime; timeCounter < relativeStartTime + totalDuration; timeCounter++) {
    /**time in seconds moving around 0 -24h in seconds */
    currentRelativeTime = timeCounter % hour24RelativeMaxTime;

    if (isTimeWithinBounds(currentRelativeTime, conditions.startTime, conditions.endTime)) {
      //time is within the condition bounds
      //duration counting
      if (!periodCountingStarted) {
        //first time the currentRelativeTime entering the bounds
        periodCountingStarted = true;
        periodStartEpoch = start + (timeCounter - relativeStartTime);
      }
    } else {
      //currentTime not within the bounds
      if (periodCountingStarted) {
        //if there's an ongoing count, end it
        durations.push({
          duration: periodDuration,
          durationIso: du.secondsToISO(periodDuration),
          startTime: du.epochToDateStr(periodStartEpoch),
          startTimeS: periodStartEpoch,
          endTime: du.epochToDateStr(start + (timeCounter - relativeStartTime)),
          endTimeS: start + (timeCounter - relativeStartTime),
        });
        totalDurations += periodDuration;
        periodDuration = 0;
      }
      periodCountingStarted = false;
    }

    if (periodCountingStarted) {
      //duration counting
      periodDuration++;
    }
  }

  if (periodCountingStarted) {
    //if there's an ongoing count which is not finished within the loop, end it
    durations.push({
      duration: periodDuration,
      durationIso: du.secondsToISO(periodDuration),
      startTime: du.epochToDateStr(periodStartEpoch),
      startTimeS: periodStartEpoch,
      endTime: du.epochToDateStr(start + (timeCounter - relativeStartTime)),
      endTimeS: start + (timeCounter - relativeStartTime),
    });
    totalDurations += periodDuration;
    periodDuration = 0;
  }

  let contineousCompletions = 0;
  if (conditions.minDuration && conditions.contineous) {
    durations.forEach((item) => {
      const minDurationCounts = Math.floor(item.duration / (conditions.minDuration ? conditions.minDuration : 0 * 60 * 60));
      if (conditions.minDuration && minDurationCounts >= 1) contineousCompletions += minDurationCounts;
    });
  }
  return { items: durations, totalDuration: totalDurations, totalDurationStr: du.secondsToISO(totalDurations), type: durationType, contineousCompletions: contineousCompletions };
}
/**
 *
 * @param activitiyStart
 * @param activityEnd
 * @param activityType
 * @param utcOffset amount of offset time for the ranges which has bounds less than 24hrs , ex longWork,nightRest,nightWork
 * @returns
 */
export function getDurationsByActivityType(activitiyStart: number, activityEnd: number, activityType: ActivityType, utcOffset: number) {
  const breakResults: DurationTypeResult[] = [];
  Object.entries(breakDurationsMapped).forEach(([key, conditions]) => {
    if (conditions.type === activityType) {
      breakResults.push(getBreakDurations(activitiyStart, activityEnd, key as DurationType, utcOffset));
    }
  });
  return breakResults;
}

/**returns the next period with the given minDuration
 * @param startTime epoch time to start finding the next period
 * @param minDuration minimum duration a nightBreak period should have ,this should be <= breakDurations.nightRest.minDuration
 */
export function getNextMinNightBreakPeriod(startTime: number, minDuration: number): IPeriod {
  let foundBreakDuration: IPeriod | null = null;
  let endTime = startTime;
  do {
    //keeping going forward by adding 24hr to endTime
    endTime += hour24RelativeMaxTime;
    const result = getBreakDurations(startTime, endTime, DurationType.nightRest);
    if (result && result.items.length > 0) {
      //has next periods
      //get the first(that means the closest,since getNextNightBreakPeriod returns ordered list of items ) result
      const foundItem = result.items.find((item) => item.duration >= minDuration);
      if (foundItem) {
        foundBreakDuration = {
          from: foundItem.startTimeS,
          to: foundItem.startTimeS + minDuration,
        };
      }
    }
  } while (!foundBreakDuration);
  return foundBreakDuration;
}

/**
 * @param activity activity to be analysed
 *
 * if includes Long or night works returns the durations as a BreakDuration array
 * else null is returned
 */
export function isLongOrNightWork(activity: Activity) {}

/**Manages DurationTypes(24HContineous,NightRest...) of an Activity */
export class DurationHandler {
  /**immutable source of duration types for an activitiy */
  readonly durationTypeResults: DurationTypeResult[];

  /**mutable source of duration types for an activitiy ,needs to be reset whenever the activity is passed to a new timeline*/
  usableDurationTypeResult: DurationTypeResult[] = [];
  /**utc offset in seconds */
  utcOffset: number;

  constructor(activityStartTime: number, activityEndTime: number, activityType: ActivityType, utcOffset: number) {
    this.utcOffset = utcOffset;
    this.durationTypeResults = getDurationsByActivityType(activityStartTime, activityEndTime, activityType, utcOffset);
    this.resetUsed();
  }
  /**resets usedDurationResult and set with deepcloned values from durationTypeResults */
  resetUsed() {
    this.usableDurationTypeResult = this.durationTypeResults.map((result) => ({ ...result, items: result.items.map((item) => ({ ...item })) }));
  }

  useLongOrNightWork() {}

  hasLongOrNightWork() {}

  /**@returns no of continous rests of given type
   * @param disableReUse if true take periods which are  not already used
   */
  getContinuousRestCount(durationType: DurationType, disableReUse = false) {
    const result = disableReUse
      ? this.usableDurationTypeResult.find((result) => result.type === durationType)
      : this.durationTypeResults.find((result) => result.type === durationType);

    if (result) return result.contineousCompletions;
    else return 0;
  }

  /**@returns no of long work of given type */
  getLongWork() {
    const result = this.usableDurationTypeResult.find((result) => result.type === DurationType.longWork);
    if (result) return result.totalDuration;
    else return 0;
  }

  /**@returns no of long work of given type */
  getNightWork() {
    const result = this.usableDurationTypeResult.find((result) => result.type === DurationType.nightWork);
    if (result) return result.totalDuration - (result.totalDuration % 60); // removing caculation error 🎉
    else return 0;
  }

  /**removes any existing periods with the input period range and is of the given durationType
   *
   * NOTE
   *
   *For hour24ContinousRest removed,correspoinding hour7ContinousRests will also be removed (nhvr rule)
   *
   * This logic can only remove periods which are small or same sized as durationTypeResultItems
   */
  removeUsedPeriod(period: Period, durationType: DurationType) {
    const result = this.usableDurationTypeResult.find((result) => result.type === durationType);
    if (!result) return;
    if (result.items && result.items.length > 0) {
      result.items = result.items.filter((item) => !(item.startTimeS <= period.from && item.endTimeS >= period.to));
    }
    if (durationType === DurationType.hour24ContineousRest) {
      //removing 7hour durations crossing the period
      const result2 = this.usableDurationTypeResult.find((result) => result.type === DurationType.hour7ContineousRest);
      if (!result2) return;

      if (result2.items && result2.items.length > 0) {
        result2.items = result2.items.filter(
          (item) => !((period.from <= item.startTimeS && period.to >= item.startTimeS) || (period.from <= item.endTimeS && period.to >= item.endTimeS)),
        );
      }
    }
  }

  /**@returns  rest periods  for rests of given type with a min cutoff and with the same order
   * @param minDuration minimum accpetable duration for the output
   * @param maxDuration max time in which a single duration of the returned list can have
   * @param maxEndTime maxtime the endTime of a duration can have
   * @param disableReUse if true returns periods from usableDurationTypeResult which will not include already used periods ,else from durationTypeResults
   */
  getContinuousRestPeriods(durationType: DurationType, minDuration: number, maxDuration: number, maxEndTime: number, disableReUse = false): Period[] {
    const result = disableReUse
      ? this.usableDurationTypeResult.find((result) => result.type === durationType)
      : this.durationTypeResults.find((result) => result.type === durationType);
    if (result && result.items.length > 0) {
      const items = result.items.filter((dur) => dur.duration >= minDuration && dur.endTimeS <= maxEndTime).map((item) => new Period(item.startTimeS, item.endTimeS));
      if (items.length > 0) {
        return items;
      }
    }
    return [];
  }

  /**@returns true if there are continuous rests of given type ,else false */
  hasContinuousRest(durationType: DurationType) {
    return this.getContinuousRestCount(durationType) > 0;
  }
}

//--------------MODELS----------------------

interface IDuration {
  /**represents a time duration in seconds*/
  duration: number;
  /**duration as a readable ISO formatted string */
  durationIso: string;
}

export class Duration implements IDuration {
  duration: number;
  durationIso: string;

  constructor(duration = 0) {
    this.duration = duration;
    this.durationIso = du.secondsToISO(duration);
  }
  /**crate a duration object
   * @param duration ,duration in seconds
   */
  static create(duration: number) {
    return new Duration(duration);
  }
  /**add seconds */
  add(seconds: number) {
    this.duration += seconds;
    this.durationIso = du.secondsToISO(this.duration);
  }
}
