import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ActivityInputOptions } from '../sections/BfmSection';
import { Activity } from '../models/Activity';
import { SubBreach } from '../models/BreachMapper';
import { ISummary } from '../models/Summary';
import { getFatigueSummary } from '../services/FatigueApi';
import { SummaryDialog } from './Modals';

export const FatigueSummary = (props: { ruleType:any,triggerReset: boolean; activities: Activity[]; inputOptions: ActivityInputOptions; selectedActivity: Activity | undefined }) => {
  const [triggerLoad, setTriggerLoad] = useState(false);
  const [summary, setSummary] = useState<ISummary>();
  /**activity list file names */
  const resetPrevRef = useRef(false);

  useEffect(() => {
    if (resetPrevRef.current !== props.triggerReset) {
      resetPrevRef.current = props.triggerReset;
    }
  }, [props.triggerReset]);

  const showLoadSummary = () => {
    if (props.activities.length === 0) return;

    const startTime = Date.now();
    //---setting customCurrentTime
    let currentTime;
    const { selectedActivity, activities } = props;
    if (props.inputOptions.useLastActivityEndTime) {
      currentTime = selectedActivity ? selectedActivity.endTimeS : activities.length > 0 ? activities[activities.length - 1].endTimeS : undefined;
    }

    getFatigueSummary(props.ruleType,selectedActivity ? activities.filter((act) => act.id <= selectedActivity.id) : activities, currentTime)
      .then((summary) => {
        setSummary(summary);
        console.log('summary', summary);
        setTriggerLoad((val) => !val);
      })
      .catch((error) => {
        // toast.error('Load file name List Failed');
        console.log(error);
        toast.error('Load Summary API Request Failed');

        setTriggerLoad((val) => !val);
      });
  };

  const summeryType = props?.ruleType?.split('_')?.[0];
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="light" className="me-2" onClick={showLoadSummary}>
          {`${summeryType} SUMMARY`}
        </Button>
      </div>
      {summary && <SummaryDialog trigger={triggerLoad} summary={summary} />}
    </>
  );
};
