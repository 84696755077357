import { Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { IMainRuleSimple } from '../../models/sfm/IMainRuleSimple';
import { PopupModal } from '../Modals';
import { MainRuleForm } from './MainRuleForm';
import { MainRuleView } from './MainRuleView';
import { getDefaultDuration, getDefaultRuleData } from './defaultData';
import { useAfmContext } from '../../services/AfmContext';
import { getSecondsFromDuration } from '../../utils/dateUtils';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

interface SelectedRuleData {
  rule: IMainRuleSimple | null;
  type: 'add' | 'edit' | 'delete' | 'select';
  idx?: number;
}

export const MainRuleHandler = (props: { mainRules: IMainRuleSimple[] , rulesetName:string|undefined; onChange: (data: IMainRuleSimple[]) => void; onRuleSelect: (data: IMainRuleSimple) => void , onNameChange:(name:string)=>void }) => {
  const [rules, setRules] = useState<IMainRuleSimple[]>([]);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedRuleData, setSelectedRuleData] = useState<SelectedRuleData>();
  const isParentUpdate = useRef(false);
  const {contextData, setContextData} = useAfmContext()

  useEffect(() => {
    if (props.mainRules) {
      isParentUpdate.current = true;
      props.mainRules.filter(r=>r.shiftRestBreak==null).forEach(rule=>rule.shiftRestBreak = getDefaultDuration())
      setRules(props.mainRules);
    }
  }, [props.mainRules]);

  useEffect(() => {
    if (!isParentUpdate.current) props.onChange(rules);
    else isParentUpdate.current = false;
     const oneDayRule = rules.find(rule=>getSecondsFromDuration(rule.period) == getSecondsFromDuration({days:1 , hours:0,minutes:0}))
    if(oneDayRule){
      setContextData({oneDayRule})
      console.log('contextData updated from child new oneDayRule')
    }

  }, [rules]);

  useEffect(() => {
    console.log('processing selectedRuleData ', selectedRuleData);
    if (selectedRuleData?.type == 'add' || selectedRuleData?.type == 'edit') {
      setShowAddEdit(true);
    } else if (selectedRuleData?.idx != undefined && selectedRuleData?.type == 'delete') {
      rules.splice(selectedRuleData.idx,1);
      setRules([...rules]);
    }
    if(selectedRuleData?.rule) props.onRuleSelect(selectedRuleData.rule)
  }, [selectedRuleData]);

  const onAddEditRuleComplete = (data: IMainRuleSimple) => {
    console.log('onAddEditRule --main', data);
    if (selectedRuleData?.type == 'add') {
      console.log('onAddEditRule --main --add');
      setRules([...rules, { ...data, tag: uuidv4() }]);
    } else if (selectedRuleData?.idx != null && selectedRuleData?.type == 'edit') {
      console.log('onAddEditRule --edit');
      rules[selectedRuleData.idx] = { ...data};
      setRules([...rules]);
    }
    setShowAddEdit(false);
    setSelectedRuleData(undefined);
  };

  return (
    <>
      <Card>
        <Card.Title>Main Rules</Card.Title>
        <Card.Body>

        <Form.Group  className="mb-3">
                  <Form.Label sm={4}>RuleSet Name</Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      placeholder="RuleSet Name"
                      onChange={(e)=>props.onNameChange(e.target.value)}
                      value={props.rulesetName}
                    />
                  </Col>
          </Form.Group>

          <Button
            size="sm"
            variant="dark"
            className="mb-3"
            onClick={() => {
              setSelectedRuleData({ rule: null, type: 'add' });
            }}
          >
            Add Main Rule
          </Button>
          <Row xs={'auto'}>
            {rules?.map((rule, idx) => (
              <Col key={rule.tag}>
                <Row xs={'auto'}>
                  <MainRuleView
                    mainRule={rule}
                    onDelete={() => setSelectedRuleData({ rule: rule, type: 'delete', idx: idx })}
                    onEdit={() => setSelectedRuleData({ rule: rule, type: 'edit', idx: idx })}
                    onClick={()=>setSelectedRuleData({ rule: rule, type: 'select', idx: idx })}
                    selected={selectedRuleData && selectedRuleData.rule?.tag == rule.tag}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
      <PopupModal
        title={selectedRuleData?.type == 'add' ? 'Add Rule' : 'Edit Rule'}
        modalProps={{ size: 'lg', fullscreen: true }}
        show={showAddEdit}
        onClose={() => setShowAddEdit(false)}
      >
        { <MainRuleForm mainRule={selectedRuleData?.rule || getDefaultRuleData()} onReset={() => console.log('restRuleResetted')} onSubmit={onAddEditRuleComplete} />}
      </PopupModal>
    </>
  );
};
