
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IRuleSetSimple } from './models/sfm/IMainRuleSimple';
import BfmSection from "./sections/BfmSection";
import AfmSection from './sections/SfmSection';


export default function App() {
  const [ruleSet, setRuleSet] = useState<IRuleSetSimple>({ rules: [], rulesetName: 'no-name' });

  return (
    <Tabs
      defaultActiveKey="BFM"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="BFM" title="BFM">
        <BfmSection afmRuleSet={ruleSet}/>
      </Tab>
      <Tab eventKey="AFM" title="AFM">
        <AfmSection onRuleSetChange={setRuleSet} />
      </Tab>
    </Tabs>
  );
}
