import { createContext, useContext, useMemo, useState, Dispatch, SetStateAction, useEffect } from "react"
import { IMainRuleSimple } from "../models/sfm/IMainRuleSimple"

interface IcontextData {
  oneDayRule:IMainRuleSimple 
}

interface AFMContextProps {
  contextData: IcontextData | null
  setContextData: Dispatch<SetStateAction<IcontextData | null>>
}

const AfmContext = createContext<AFMContextProps>({
  contextData: null,
  setContextData: (prevState: SetStateAction<IcontextData | null>) => prevState,
})

interface AFMContextProviderProps {
  children: React.ReactNode
}

function AfmContextProvider({ children }: AFMContextProviderProps): JSX.Element {
  const [contextData, setContextData] = useState<IcontextData | null>(null)



  const value = useMemo(() => ({ contextData, setContextData }), [contextData, setContextData])

  return <AfmContext.Provider value={value}>{children}</AfmContext.Provider>
}

const AfmContextConsumer = AfmContext.Consumer

const useAfmContext = () => useContext(AfmContext)

export { AfmContextProvider , AfmContextConsumer, useAfmContext }