import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Activity, IBaseActivity } from '../models/Activity';
import { dateToEpoch, nowTimeInEpoch } from '../utils/dateUtils';
import { LoadActivityFileConfirmation, SaveActivitiesMetaData, SaveActivityAsFileConfirmation } from './Modals';
import { getActivityFileNames, getActivityList, IBreachedSaveFormat, saveActivitiesList } from '../services/FatigueApi';
import { SubBreach } from '../models/BreachMapper';
import { ActivityType } from '../common/Types';
import { RuleBreachCounter } from '../models/RuleBreachCounter';
import { exportData } from '../utils/utils';

export type ActivitiesSaveFormat = {
  activities: IBaseActivity[];
  description?: string;
  subBreachName?: string;
  validBreached?: IBreachedSaveFormat[];
};

export const SaveLoad = (props: {
  triggerReset: boolean;
  activities: Activity[];
  validBreached: RuleBreachCounter[];
  onActivitesLoaded: (activities: Activity[], breaches: SubBreach[], validBreached: IBreachedSaveFormat[]) => void;
}) => {
  const [triggerSave, setTriggerSave] = useState(false);
  const [triggerLoad, setTriggerLoad] = useState(false);
  /**activity list file names */
  const [fileNames, setFileNames] = useState<string[]>([]);
  const resetPrevRef = useRef(false);

  useEffect(() => {
    if (resetPrevRef.current !== props.triggerReset) {
      resetPrevRef.current = props.triggerReset;
    }
  }, [props.triggerReset]);

  const showLoadActivities = () => {
    getActivityFileNames()
      .then((names) => {
        setFileNames(names);
        setTriggerLoad((val) => !val);
      })
      .catch((error) => {
        // toast.error('Load file name List Failed');
        console.log(error);
        setTriggerLoad((val) => !val);
      });
  };

  const loadActivities = (fileName: string) => {
    getActivityList(fileName)
      .then((baseActivities) => {
        console.log('base activities', baseActivities);
        const { finalActivities, finalBreaches } = mapBaseActivities(baseActivities);
        props.onActivitesLoaded(finalActivities, finalBreaches, []);
        toast.success('Load Activities success');
      })
      .catch((error) => {
        toast.error('Load Activities Failed');
        console.log(error);
      });
  };

  const mapBaseActivities = (baseActivities: IBaseActivity[]) => {
    //add epochTime(=startTimeS) variable and sort list using it
    const sortedBaseActivities = baseActivities.map((act) => ({ ...act, startTimeS: dateToEpoch(act.startTime) })).sort((a, b) => a.startTimeS - b.startTimeS);
    const lastActivityIndex = baseActivities.length - 1;
    const finalActivities: Activity[] = [];
    const finalBreaches: SubBreach[] = [];

    let breachCounter = 0;
    sortedBaseActivities.forEach((bActivity, index) => {
      let activity: Activity;
      //-------activity mapping---------
      if (index < lastActivityIndex) {
        if (!sortedBaseActivities[index + 1]) throw Error('invalid activity at :' + (index + 1));
        activity = Activity.withEndTime(index, bActivity.startTime, bActivity.type, sortedBaseActivities[index + 1].startTime);
        finalActivities.push(activity);
      } else {
        // if (bActivity.endTime && bActivity.type === ActivityType.work) {
        //   //if endTime for last activity does exist and is a work then new rest task is added to the end with endTime as curent time
        //   const workActivity = Activity.withEndTime(index, bActivity.startTime, bActivity.type, bActivity.endTime);
        //   const restActivity = Activity.asLastActivity(index + 1, bActivity.startTime, ActivityType.rest);
        //   finalActivities.push(workActivity, restActivity);
        //   activity = workActivity;
        // } else {
        //   activity = Activity.asLastActivity(index, bActivity.startTime, bActivity.type);
        //   finalActivities.push(activity);
        // }
        if (bActivity.endTime) {
          //if endTime for last activity does exist
          const newActivity = Activity.withEndTime(index, bActivity.startTime, bActivity.type, bActivity.endTime);
          finalActivities.push(newActivity);
          activity = newActivity;
        } else {
          activity = Activity.asLastActivity(index, bActivity.startTime, bActivity.type);
          finalActivities.push(activity);
        }
      }

      //-------breach mapping---------
      if (bActivity.breaches) {
        bActivity.breaches.forEach((breach) => {
          const subBreach = SubBreach.fromName(breachCounter, breach.name, activity);
          finalBreaches.push(subBreach);
          activity.addBreach(subBreach);
          breachCounter++;
        });
      }
    });

    return { finalActivities, finalBreaches };
  };

  const saveActivities = (data: SaveActivitiesMetaData, download = false) => {
    const { fileName, description, subBreachName } = data;
    const lastActivityIndex = props.activities.length - 1;
    const savingActivities: IBaseActivity[] = props.activities.map((act, index): IBaseActivity => {
      if (lastActivityIndex !== index || (lastActivityIndex === index && !act.endTime))
        return { startTime: act.startTime, type: act.type, breaches: act.breaches ? act.breaches.map((b) => b.getSaveObject()) : undefined };
      //add endTime for lastActivity for reference when loading back to get the correct duration
      else return { startTime: act.startTime, endTime: act.endTime, type: act.type, breaches: act.breaches ? act.breaches.map((b) => b.getSaveObject()) : undefined };
    });

    const saveData: ActivitiesSaveFormat = {
      activities: savingActivities,
      description,
      subBreachName,
      validBreached: props.validBreached.map((rc) => ({ ...rc.source, ruleCounterIdx: rc.id } as IBreachedSaveFormat)),
    };

    if (download) {
      exportData(saveData, fileName);
      toast.success('Download Success');
      return;
    }
    saveActivitiesList(saveData, fileName)
      .then((res) => {
        toast.success('File Saved Successfully');
      })
      .catch((er) => {
        toast.error('File Save Failed');
        console.log('save failed', er);
      });
  };



  const processUploaded = (data: any) => {
    try {
      console.log('processUploaded-start', data);
      const { finalActivities, finalBreaches } = mapBaseActivities(data.activities);
      props.onActivitesLoaded(finalActivities, finalBreaches, data.validBreached ? data.validBreached : []);
      toast.success('Load Activities success');
    } catch (e) {
      toast.error('file upload failed');
      console.log('error occurred while processing uploaded', e);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="danger" className="me-2" onClick={showLoadActivities}>
          Load Activites
        </Button>
        <Button
          variant="info"
          onClick={() => {
            setTriggerSave((val) => !val);
          }}
        >
          Save Activites
        </Button>
      </div>
      <SaveActivityAsFileConfirmation trigger={triggerSave} onConfirmation={saveActivities} />
      <LoadActivityFileConfirmation trigger={triggerLoad} fileNames={fileNames} onConfirmation={loadActivities} onDataUpload={processUploaded} />
    </>
  );
};
