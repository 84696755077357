import { Button, Card, Container, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from 'react';
import { IBreachSimple, IMainRuleSimple, IRestRuleSimple, IRuleDuration } from '../../../models/sfm/IMainRuleSimple';
import { DurationForm } from '../DurationForm';
import { getDefaultBreach, getDefaultDuration, getDefaultRestRuleData, getDefaultRuleData, MainRuleData } from '../defaultData';
import { Severity } from '../../../models/RuleBreachCounter';

const validationSchema = Yup.object().shape({
  // days: Yup.number().min(0),
  // hours: Yup.number().min(0),
  // minutes: Yup.number().min(0),
});

export const BreachForm = (props: {
  breach: IBreachSimple | null;
  mainRule: IMainRuleSimple;
  isWorkBreach: boolean;
  onSubmit: (data: IBreachSimple) => void;
  onReset: () => void;
}) => {
  const [formData, setFormData] = useState<IBreachSimple>(getDefaultBreach());

  useEffect(() => {
    if (props.breach) {
      setFormData({ ...props.breach });
    }
  }, [props.breach]);

  const onDataSubmit = (data: any) => {
    console.log('got mainData', data);
    setFormData(data);
    props.onSubmit(data);
  };

  return (
    <Card>
      <Card.Body>
        <Formik enableReinitialize={true} initialValues={formData} onSubmit={onDataSubmit} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, handleSubmit }) => (
            <Form>
              <Form.Group className="mb-3">
              <Form.Select aria-label="Severity" name='severity' onChange={(evt)=>setFieldValue('severity', parseInt(evt.target.value))} value={values.severity}>
                <option>select a severity</option>
                <option value={Severity.zero}>Zero</option>
                <option value={Severity.minor}>Minor</option>
                <option value={Severity.substantial}>Substantial</option>
                <option value={Severity.severe}>Severe</option>
                <option value={Severity.critical}>Critical</option>

              </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label className="col-sm-2 col-form-label">From</Form.Label>
                <DurationForm
                  inputDuration={formData.period.from || null}
                  onReset={() => setFieldValue('period.from', getDefaultDuration())}
                  onSubmit={(data: IRuleDuration) => setFieldValue('period.from', data)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="col-sm-2 col-form-label">To</Form.Label>
                <DurationForm
                  inputDuration={formData.period.to || null}
                  onReset={() => setFieldValue('period.to', getDefaultDuration())}
                  onSubmit={(data: IRuleDuration) => setFieldValue('period.to', data)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>message</Form.Label>

                <Form.Control name="msg" type="text" placeholder="Alert Message" onChange={handleChange} onBlur={handleBlur} value={values.msg} />
              </Form.Group>

              <Form.Group className="mt-3">
                <Button
                  size="lg"
                  variant="success"
                  className="btn btn-primary me-2"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button variant="warning" onClick={() => props.onReset()}>
                  RESET
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};
